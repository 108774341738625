<template>
  <div>
    <button class="btn-g gray show " v-on:click="getPosts()">refresh</button>
    <h1>POSTS</h1>
    <div v-show="graphicPost != undefined" v-for="(post, index) in graphicPost" :key="index" class="shadowBox" style="max-width: 1000px; margin: 40px auto; display:block; background: #fff; border-radius: 10px ; overflow: hidden;"  >
      <div style="padding: 20px;">
        <img  :src="post.header.logo" alt="" style="width: 50px; float:left; margin: 0 20px 0 0;">
        <p style="float:left; margin: 12px 0;" > <strong>{{post.header.date}}</strong> </p>
        <p style="clear:both; margin: 60px 0 0 0 ;">{{post.header.subject}}</p>
        <br>
      </div>
      <paper-layout :layout="JSON.parse(post.post)" :models="[]" />
    </div>
  </div>
</template>
<script>
//AWS - GRAPHQL
  import { smd_generateGraphicPost } from '../graphql/queries'
  //Tools
  import Vuex from 'vuex'
  import { API } from 'aws-amplify'
  import Swal from 'sweetalert2'
  //import { v4 as uuidv4 } from 'uuid';
  //Components
  import PaperTable from '../components/papers/PaperTable.vue'
  import PaperTableView from '../components/papers/PaperTableView.vue'
  import PaperSmart from '../components/papers/PaperSmart.vue'
  import PaperHistory from '../components/papers/PaperHistory.vue'
  import PaperLayout from '../components/papers/PaperLayout.vue'
  import PaperDisplayer from '../components/papers/PaperDisplayer.vue'
  import PaperRecord from '../components/papers/PaperRecord.vue'
  import UiChartsV2 from '../components/ui/UiChartsV2.vue'

  import UiButton from '../components/ui/UiButton.vue'
  export default {
    components:{
      PaperTable,
      PaperSmart,
      PaperHistory,
      PaperTableView,
      UiButton,
      PaperLayout,
      PaperDisplayer,
      PaperRecord,
      UiChartsV2,
    },
    data() {
      return {
        graphicPost: undefined,
      }
    },
    created() {
      console.clear()
      this.setPublicOrganization(undefined)
      this.setPublicOrganizationData(undefined)
      this.loading('show')
      this.setUserWeb()
    },
    methods: {
      ...Vuex.mapActions(['getPublicOrganization','getPublicOrganizationData']),
      ...Vuex.mapMutations(['setStatusReload','setPublicOrganizationData','setPublicOrgPay','setPublicOrganization','setContactSK']),
      //Tools
      loading(action){
        if (action == 'show') {
          document.getElementById("loading").classList.add('show')
          document.getElementById("body").classList.add("noScroll")
        }else{
          document.getElementById("loading").classList.remove('show')
          document.getElementById("body").classList.remove("noScroll")
        }
      },
      async setUserWeb(){
        this.loading('show')
        console.log('>>> USER: '+this.user);
        if (this.user == 'Not authorized') {
          Auth.signIn('smd_webuser', '383@JDU45ss#')
          .then((user) => { 
            this.getPublicOrganization()//Alias
            setTimeout(() => {
              this.getPosts()
              this.loading('hidde')
            }, 1000);
          })
          .catch((err) => console.log(err));
        }else{
          this.getPublicOrganization()//Alias
          setTimeout(() => {
            this.getPosts() 
            this.loading('hidde')
          }, 500);
        }
      },
      async getPosts(){
        let pullData = ''
        console.log(this.publicOrganization);
        console.log(this.$router.currentRoute.value.query.data);
        try {
          pullData = await API.graphql({
            query: smd_generateGraphicPost,
            variables:{
              itemPK:this.publicOrganization,
              itemSK:this.$router.currentRoute.value.query.data,
            }
          })
          pullData = pullData.data.smd_generateGraphicPost
          this.graphicPost =JSON.parse(pullData.data)
          console.log(JSON.parse(pullData.data));
        } catch (error) {
          console.log(error);
        }
      },
    },
    
    computed:{
      ...Vuex.mapState(['organization','user','publicOrganizationData','publicOrganization'])
    },
  }
</script>